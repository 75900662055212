@charset "utf-8";

/* import main fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*color*/
$white: #fff;
$gray: #bbb;
$black: #171A1D;
$general: #727272;
$black_soft: #333;
$black_col: #2e333c;
$black_more: #0f0f0f;
$color: #137DC5;

/*font*/
* {
  --body-font: "Roboto", Helvetica, Arial, sans-serif;
  --title-font: "Roboto", Helvetica, Arial, sans-serif;
}

/*general*/
html {
  scroll-behavior: auto;
}

body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  color: #909090;
  background: #ffffff;
  line-height: 30px;
  padding: 0;
  line-height: 1.8em;
  word-spacing: 0px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.col-white {
  color: $white;
}

.color {
  color: $color;
}

.transition {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

h1,
h2,
h4,
h3,
h5,
h6,
.h1_big,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
footer.footer-light h5 {
  margin-top: 0;
  font-family: var(--title-font);
  font-weight: bold;
  color: #fff;
}


.carousel .slide {
  background-color: #171a1d !important;
}

.margin-top-container {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

;

a {
  font-family: var(--title-font);
  display: inline-block;
  text-align: center;
  color: rgb(248, 248, 248);
  text-decoration: none;

}

.h1_big {
  color: $white;
  position: relative;
  left: -3px;
  top: -10px;
  font-size: 64px;
  line-height: 70px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 42px;
}

.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}

.spacer-half {
  width: 100%;
  height: 15px;
  display: block;
  clear: both;
}

/* preloader */
#mainpreloader {
  .preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 9999999;
    -webkit-animation-duration: 1.6s;
    animation-duration: 1.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 1;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 1;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
}

.mainpreloader {
  position: relative;
  animation: rotate 1s infinite linear;
  border: 3px solid rgba(15, 21, 209, 0.1);
  width: 100px;
  height: 100px;
  margin-right: -24px;
  border-radius: 999px;
}

.mainpreloader span {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 4px solid transparent;
  border-top: 4px solid rgba(0, 77, 232, 0.7);
  top: -3px;
  left: -3px;
  border-radius: 999px;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#root,
.App {
  background: #fff;
}

#routerhang {
  transform: unset !important;
  transform-origin: unset !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.burgermenu {
  width: 32px;
  height: 32px;
  padding: 4px;
  text-align: center;
  font-size: 20px;
  display: none;
  position: fixed;
  top: 20px;
  right: 25px;
  border: none;
  background: none;
  color: $white;
  transition: .5s;

  &:hover {
    background: $white;
    color: $black;
    transition: .5s;
  }

  i {
    position: relative;
    top: -2px;
  }
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  padding-bottom: 10px !important;
  margin: 0;
  list-style: none;
  text-align: center;
  background-color: $black !important;
}


section,
.section {
  position: relative;
  padding: 32px 0;
  width: 100%;
  height: auto;
  background-size: cover;
  background-position-y: center;
}

.py-5.position-relative {
  z-index: 2;
}

.spacer-single {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-double {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

.v-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.list_location,
.list_location li {
  list-style: none;
  padding: 0;
}

.list_location li {
  color: #bbbbbb;
  display: inline-block;
  margin-right: 35px;
  font-size: 14px;
  border-left: solid 1px rgba(0, 0, 0, .25);
  border-left-color: rgba(255, 255, 255, .25);
  padding-left: 10px;

  span {
    font-family: var(--title-font);
    color: $white;
    font-size: 16px;
    font-weight: bold;
    display: block;
  }
}

.float-text {
  width: 20px;
  position: fixed;
  z-index: 1002;
  margin-left: 30px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 12px;
  top: 50%;
  left: 15px;
  opacity: 1;
  transform: translate(-50%, -50%);

  span {
    margin-left: -5px;
    margin-top: 20px;
    writing-mode: vertical-rl;
    letter-spacing: .75px;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    color: rgba(255, 255, 255, .5);
  }

  .de_social-icons {
    span.buton {
      position: relative;
      left: 1px;
      top: 1px;
      color: $color;
      cursor: pointer;
      margin-top: 0;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}

/*mouse*/

@-webkit-keyframes finger {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes finger {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

.space-border {
  width: 30px;
  height: 1px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-bottom: 40px;
  background: $color;

  &:before {
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    left: -40px;
    background: rgba(255, 255, 255, .25);
  }

  &:after {
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    right: -40px;
    background: rgba(255, 255, 255, .25);
  }
}

.space-border2 {
  width: 30px;
  height: 1px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-bottom: 10px;
  background: $color;

  &:before {
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    left: -40px;
    background: rgba(255, 255, 255, .25);
  }

  &:after {
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    right: -40px;
    background: rgba(255, 255, 255, .25);
  }
}

.flexing_langages {
  display: flex;
  justify-content: center;
  align-items: center;
}


/*menu*/
.navbar-brand {
  cursor: pointer;
  z-index: 2;

  .imginit {
    display: block;
  }
}

#header-wrap {
  width: 100%;
  position: fixed;
  z-index: 1001;

  nav.navbar {
    font-family: var(--title-font);
    font-size: 13px;
    font-weight: 600;
    padding: 0;

    &.rev {
      padding: 20px 0;
    }
  }

  &.transparent {
    background: none;
  }

  &.sticky {
    nav.navbar {
      background: $black;
    }
  }
}

.navbar-nav {
  display: inline-block !important;

  .nav-item {
    display: inline-block;

    a {
      padding: 30px 18px;
      padding-right: 6px;
      color: $white;
      cursor: pointer;

      &.active {
        color: $color;
      }
    }
  }
}

blockquote {
  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;
  border-left: solid 3px #333333;
  padding-left: 30px;
}

blockquote.q-big {
  color: #fff;
  font-size: 32px;
  border: none;
  margin: 0;
  padding: 0;
}

blockquote.q-big .d-big {
  font-size: 150px;
  display: block;
  color: $color;
}

.d-quote-by {
  font-family: var(--body-font);
  display: block;
  font-size: 14px;
  margin-top: 20px;
  border-left: solid 1px rgba(255, 255, 255, .2);
  padding-left: 20px;
  line-height: 1.5em;
}

.icon_quotations::before {
  content: "\7b";
}

.de_3d-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(34, 34, 34, .0);
  background-size: auto;
  position: relative;
  border-left: solid 1px rgba(255, 255, 255, .2);

  .d-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 40px;
  }

  i {
    position: absolute;
    font-size: 40px;
    top: 38px;
    left: 30px;
    color: $color;
  }

  h3 {
    margin-left: 50px;
    margin-top: 5px;
    margin-bottom: 25px;
  }
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #137DC5 !important;
  -webkit-font-smoothing: antialiased;
}

.slick-dots li button::before {
  font-size: 9px !important;
  color: grey !important;
  opacity: 1;
}


/*Galery*/
.my-gallery-class {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}

.image-element-class {
  padding: 1em;

  img {
    width: 110%;
    height: 350px;
    object-fit: contain;
  }
}

.de_modal {
  cursor: pointer;

  .card-image-1 {
    position: relative;
    overflow: hidden;
    margin: 10px;
    transition: .5s;
    outline: none;
    height: 100%;

    .d-text {
      color: #ffffff;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      padding: 10%;
      transform: scale(1.1);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10%;
      background: rgba(30, 30, 30, 0);
      background-size: auto;
      transition: .5s;
    }

    h3 {
      opacity: 0;
      margin: 0;
      font-size: 22px;
      transition: .5s;
    }

    h5 {
      opacity: 0;
      font-weight: 300;
      font-size: 14px;
      color: #ffffff;
      position: absolute;
      left: 0;
      bottom: 5%;
      width: 100%;
      text-align: center;
      transition: .5s;
    }

    img {
      transform: scale(1.1);
      filter: grayscale(100%);
      transition: .5s;
    }

    &:hover {
      z-index: 1;
      -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
      -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
      box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
      transition: .5s;

      .d-text {
        transform: scale(1);
        background: rgba(30, 30, 30, .3);
        background-size: auto;
      }

      h3 {
        opacity: 1;
        transition: .5s;
      }

      h5 {
        opacity: 1;
        bottom: 5%;
        transition: .5s;
      }

      img {
        transform: scale(1);
        filter: grayscale(0);
        transition: .5s;
      }
    }
  }
}

.LightboxGal {

  width: 100%;
  height: 100%;
  background: #ffffff;

  overflow: auto;

  .mainLightbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4%;
  }

}


.de_project-details {
  color: #aaaaaa;

  .d-field {
    padding: 5px 0;
    margin-bottom: 5px;
    border-bottom: solid 1px rgba(255, 255, 255, .1);

    i {
      color: #ffffff;
      margin-right: 10px;
    }
  }

  span {
    float: right;

    a {
      text-decoration: none;
      color: $color;
    }
  }
}

.de_project-info {
  h3 {
    font-size: 24px;
    margin-bottom: 25px;
  }

  p {
    color: #aaa;
    margin-bottom: 20px;
  }

  .spacer-30 {
    width: 100%;
    height: 30px;
    display: block;
    clear: both;
  }
}

.image-props {
  object-fit: contain !important;
  background-color: $black;
  height: 500px;
  max-height: 500px;
}

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 77px !important;
  padding: 5px;
}



/* blog */
.bloglist.item {
  margin-right: 25px;
}

.de_modal {
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    transform: scale(1);
    filter: grayscale(100);
    transition: .5s;
  }
}

.post-image {
  overflow: hidden;

  &:hover img {
    width: 100%;
    margin-bottom: 0px;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
    transform: scale(1.1);
    transition: .5s;
    outline: none;
  }
}

.post-text {
  padding-top: 20px;

  h4 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  p {
    color: #aaaaaa;
  }
}

.paddingClass {
  padding-bottom: 0 !important;
  padding-top: 0 !important
}


.arrow-wrapper-link {
  position: absolute;
  display: block;
  top: 25px;
  left: 15px;
  z-index: 99,
}

// Custom Slick-carousel
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  width: 40px !important;
  height: 40px !important;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-next {
  right: -40px !important;
}

.slick-prev {
  left: -40px !important;
}

.slick-dots li {
  margin: 0;
}

.slick-dots li button::before {
  font-size: 9px !important;
  color: rgba(255, 255, 255, .3);
  opacity: 1;
}

.slick-dots li.slick-active button::before {
  font-size: 10px !important;
  color: #137DC5 !important;
}

.blog-read img {
  margin-bottom: 30px;

  &.img-fullwidth {
    width: 100%;
    height: auto;
  }
}

.post-like::before,
.post-by::before,
.post-comment::before {
  font-family: FontAwesome;
  padding-right: 10px;
  font-size: 11px;
}

.post-like::before {
  content: "\f08a";
}

.post-comment::before {
  content: "\f0e5";
}

.post-date,
.post-comment,
.post-like,
.post-by,
.post-author {
  margin-right: 20px;
  font-size: 13px;
  color: $white;
  font-family: var(--body-font);
}

.post-text {
  padding-top: 0;
}

#blog-comment ol {
  padding-left: 40px;

  li {
    list-style: none;
    margin-left: -40px;
    padding-left: 0;
    min-height: 70px;
    line-height: 1.6em;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #eee;
    border-bottom-color: rgba(255, 255, 255, .1);

    .avatar {
      position: absolute;
      margin-top: 5px;

      img {
        width: 80px;
        border-radius: 100%;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
      }
    }

    .comment-info {
      margin-left: 100px;
      margin-bottom: 5px;

      .c_name {
        display: block;
        font-weight: 700;
        color: #ffffff;
      }

      .c_date {
        font-size: 12px;
        color: $color;
      }

      .c_reply {
        padding-left: 20px;
        margin-left: 20px;
        border-left: solid 1px #ddd;
        border-left-color: rgba(255, 255, 255, .2);
        font-size: 12px;
        color: $color;
      }
    }

    .comment {
      margin-left: 100px;
    }
  }
}

/* contact */
.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-border input[type="text"] {
  padding: 8px;
  margin-bottom: 20px;
  border: none;
  color: #ffffff;
  border: solid 1px rgba(255, 255, 255, .2);
  background: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;

  &:focus {
    color: #fff;
    background: rgba(var(--primary-color-rgb), .1);
  }
}

.form-border textarea.form-control {
  min-height: calc(1.5em + (.75rem + 2px));
}

#contact_form textarea {
  height: 168px;
  color: #ffffff;
  border: solid 1px rgba(255, 255, 255, .2);
  background: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;

  &:focus {
    color: #fff;
    background: rgba(var(--primary-color-rgb), .1);
  }
}

.mt30 {
  margin-top: 30px;
}

.btn-main {
  display: inline-block;
  text-align: center;
  color: #fff;
  background: $color;
  outline: 0;
  text-decoration: none;
  padding: 4px 40px;
  font-size: 14px;
  border: none;
  font-weight: bold;
  transition: .5s;

  &:hover {
    transition: .5s;
    box-shadow: 2px 2px 20px 0px rgba(var(--primary-color-rgb), 0.5);
  }
}

#success,
#failed {
  color: $color;
  margin-top: 30px;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }
}

/* to top */
#scroll-to-top {
  cursor: pointer;
  width: max-content;
  height: max-content;
  position: fixed;
  right: 10px;
  z-index: 999;

  &.init {
    bottom: -60px;
    transition: all 0.3s ease;
  }

  &.show {
    bottom: 30px;
    transition: all 0.3s ease;
  }

  div {
    font-size: .8rem;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 30px;
    border-radius: 30px;
    border: solid 1px rgba(255, 255, 255, .2);
    background: #171A1D;
    transition: all 0.3s ease;

    i {
      color: $white;

      &:before {
        font-family: "FontAwesome";
        font-size: 18px;
        content: "\f106";
        position: relative;
        top: 2px;
      }
    }

    &:hover {
      background: #1b1b1b;
      transform: scale(1.1);
      transition: all 0.3s ease;
    }
  }
}

/* footer */
footer {
  position: relative;
  color: rgba(255, 255, 255, .8);
  padding: 20px 0;
  background: rgba(0, 0, 0, .1);
  border-top: solid 1px rgba(255, 255, 255, .1);

  .social-icons {
    display: block;
    float: right;
  }

  a {
    color: rgba(255, 255, 255, .5);
    text-decoration: none;

    &:hover {
      color: rgba(255, 255, 255, .5);
    }

    i {
      font-size: 16px !important;
      color: #ffffff;
      text-shadow: none;
      padding: 12px 10px 8px 10px;
      width: 34px;
      height: 34px;
      text-align: center;
      border-radius: 30px;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      margin: 0 3px 0 3px;
      transition: .5s;

      &:hover {
        background: #fff;
        border-color: #eceff3;
        color: #333;
        transition: .5s;
      }

      &:before {
        position: relative;
        top: -2px;
      }
    }
  }
}




/* menu reveal */
#de-menu-reveal {
  float: right;
  width: 36px;
  height: 36px;
  padding: 6px;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 2;

  .lt,
  .lm,
  .lb {
    display: block;
    position: absolute;
    width: 36px;
    height: 2px;
    background: #ffffff;
    transition: .5s;
  }

  .lt {
    top: 5px;
  }

  .lm {
    top: 15px;
  }

  .lb {
    top: 25px;
  }

  &.menu-open .lt {
    transition: .5s;
    top: 15px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &.menu-open .lm {
    width: 0;
    opacity: 0;
    transition: .5s;
  }

  &.menu-open .lb {
    transition: .5s;
    top: 15px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.mainreveal {
  display: table;
  opacity: 0;
  transform: scale(0);
  background: #191A1C;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: .5s;

  .navbar-nav {
    display: table-cell !important;
    vertical-align: middle;

    li {
      display: block;
      text-align: center;
      margin: 10px 0;
      font-size: 20px;

      a {
        padding: 0;
        font-weight: 400;
      }
    }
  }

  &.show {
    opacity: 1;
    transform: scale(1);
    transition: .5s;
  }
}

/*** media all ***/
@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1240px;
  }

  .float-text {
    display: block;
  }

  .mobilemenu {
    display: none;
  }
}

@media only screen and (max-width: 1280px) {
  .float-text {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .burgermenu {
    display: block;
    z-index: 1;
  }

  .navbar-brand {
    z-index: 1;
    padding-bottom: 0 !important;
  }

  .mobilemenu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #171A1D;
    padding: 0 0 0 0;
    padding: 70px 20px;

    .navbar-nav .nav-item {
      display: block !important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin: 0;
      padding: 0px 0px;
      width: 100%;
    }
  }

  #header-wrap nav.navbar .navbar-nav .nav-item a {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-color: rgba(255, 255, 255, .1);
  }

  .dekstopmenu {
    display: none;
  }

  #header-wrap nav.navbar {
    padding: 0 0;
  }

  .bloglist.item {
    margin-right: 0px;
    padding: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 760px !important;
  }

  .h1,
  h1.big,
  .h1_big,
  h1.ultra-big {
    font-size: 32px;
    line-height: 35px;
  }

  footer {
    text-align: center;

    .social-icons {
      margin: 10px auto;
      float: unset;
    }
  }

  .CircularProgressbar {
    width: 80% !important;
  }

  .navbar-brand {
    padding-bottom: 10px !important;
  }
}

@media only screen and (max-width: 540px) {
  .margin-top-container {
    display: flex;
    flex-direction: column;
    margin-top: 0
  }

  ;



  .LightboxGal .mainLightbox {
    width: 100%;
    display: block;
    padding-top: 0;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .margin-after-photo {
    padding-left: 15px;
    padding-right: 15px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 1em;
    list-style: none;
    text-align: center;
  }

  .custom-btn-width {
    width: 50% !important;
  }

  .navbar-brand {
    padding-bottom: 10px !important;
  }
}


@media only screen and (max-width: 400px) {
  .LightboxGal .mainLightbox {
    width: 100%;
    padding-top: 0;
  }

  .navbar-brand {
    padding-bottom: 10px !important;
  }
}